import { Body } from '@sumup-oss/circuit-ui';
import type { FC } from 'react';

import type { Product } from 'productSelection/types/products';
import { StyledNextImage } from 'shared/components/StyledNextImage';
import { getResizedUrl } from 'shared/services/imageUrl';
import { formatCurrencyWithLocale } from 'utils/currency';

import {
  CustomMarginText,
  ImageWrapper,
  InstallmentContainer,
  ListItem,
  QuantityPriceContainer,
  StyledBolded,
  TextContainer,
} from './ProductListItem.styles';
import { QuantitySelector } from './QuantitySelector';

const DEFAULT_IMAGE_SIZE = 128;

export interface Props extends Product {
  className?: string;
  quantityText: string;
  isQuantitySelectionEnabled?: boolean;
  onChangeQuantity: (quantity: number) => void;
  usedForCart?: boolean;
  quantityLimit?: number;
  formattedInstallmentAmount?: string;
  imgHydration?: 'lazy' | 'eager';
  isVatReversalPromoCodeApplied: boolean;
  locale: string;
}

/**
 * The product list item used to render the product
 * information on theOrderOverview component.
 */
export const ProductListItem: FC<Props> = ({
  quantityText,
  name,
  quantity,
  formattedUnitAmount,
  totalAmountFloat,
  discountFloat,
  productContent: { imageSrc, imageAltText },
  isQuantitySelectionEnabled,
  usedForCart = false,
  onChangeQuantity,
  className,
  numberOfInstallments,
  formattedInstallmentAmount,
  quantityLimit,
  imgHydration = 'lazy',
  isVatReversalPromoCodeApplied,
  locale,
}: Props) => {
  const resizedImageUrl = getResizedUrl(
    imageSrc,
    DEFAULT_IMAGE_SIZE,
    DEFAULT_IMAGE_SIZE,
  );

  const hasInstallments = numberOfInstallments > 1;

  const unitAmount = isVatReversalPromoCodeApplied
    ? formatCurrencyWithLocale(
        (totalAmountFloat + discountFloat) / quantity,
        locale,
      )
    : formattedUnitAmount;

  return (
    <ListItem className={className} usedForCart={usedForCart}>
      <ImageWrapper>
        <StyledNextImage
          src={resizedImageUrl}
          alt={imageAltText}
          width={DEFAULT_IMAGE_SIZE}
          height={DEFAULT_IMAGE_SIZE}
          loading={imgHydration}
          objectFit="contain"
        />
      </ImageWrapper>

      <TextContainer>
        <CustomMarginText variant="highlight">{name}</CustomMarginText>
        <QuantityPriceContainer>
          {isQuantitySelectionEnabled ? (
            <QuantitySelector
              onChange={onChangeQuantity}
              quantity={quantity}
              quantityLimit={quantityLimit}
            />
          ) : (
            <Body css={{ marginBottom: 0 }}>
              {quantityText}: &nbsp;
              <StyledBolded>{quantity}</StyledBolded>
            </Body>
          )}

          {hasInstallments ? (
            <InstallmentContainer>
              <Body
                size="s"
                color="subtle"
                css={{ marginRight: 'var(--cui-spacings-bit)' }}
              >
                {numberOfInstallments}x
              </Body>
              <Body>{formattedInstallmentAmount}</Body>
            </InstallmentContainer>
          ) : (
            <Body>{unitAmount}</Body>
          )}
        </QuantityPriceContainer>
      </TextContainer>
    </ListItem>
  );
};
