import type { NextApiRequest } from 'next';

import {
  floorDecimal,
  installmentPrice,
} from 'checkout/services/InstallmentService';
import type { DigitalProduct, Product } from 'productSelection/types/products';
import type { Promotion } from 'shared/infra/commerceLayer/promotions';
import type {
  SkuCodePurchaseLimit,
  SkuPriceData,
} from 'shared/infra/storefront/markets/prices';
import { getProductDiscountRate } from 'shared/services/DiscountService';
import { formatCurrencyWithLocale } from 'utils/currency';

export interface ProductWithPrices extends Product {
  couponPromotion: Promotion;
  formattedAmount: string;
  formattedAmountWithTaxes: string;
  formattedCompareAmount: string;
  discountRate: number;
  compareAtAmountFloat: number;
  formattedInstallmentAmount?: string;
  formattedInstallmentOriginalAmount?: string;
  purchaseLimit?: number;
  appliedCouponCode?: string;
  stockQuantity: number | null;
  formattedTaxMessage: string;
}

export type DigitalProductWithoutPrices = Partial<ProductWithPrices>;

export const applyDefaultTaxRate = (amount: number, taxRate = 0): number =>
  amount * (1 + taxRate / 100.0);

export const getDefaultTaxAmount = (amount: number, taxRate = 0): number =>
  amount * (taxRate / 100.0);

export const getFormattedDefaultTaxAmount = (
  amount: number,
  locale: string,
  taxRate = 0,
): string => {
  const taxAmount = getDefaultTaxAmount(amount, taxRate);
  return formatCurrencyWithLocale(taxAmount, locale);
};

export const getFormattedAmountWithDefaultTaxRate = (
  amount: number,
  locale: string,
  taxRate = 0,
): string => {
  const amountWithDefaultTaxes = applyDefaultTaxRate(amount, taxRate);
  return formatCurrencyWithLocale(amountWithDefaultTaxes, locale);
};

export const getFormattedInstallmentAmount = (
  price: number,
  installment: number,
  locale: string,
): string =>
  formatCurrencyWithLocale(
    floorDecimal(installmentPrice(price, installment)),
    locale,
  );

const getTaxMessage = (
  suffix: string,
  price: SkuPriceData,
  locale: string,
): string => {
  if (price.hasTax) {
    return `${formatCurrencyWithLocale(
      price.totalAmountWithTaxesFloat,
      locale,
    )} ${suffix}`;
  }
  return '';
};

export const isDigitalProduct = (
  product: ProductWithPrices | DigitalProductWithoutPrices,
): product is DigitalProduct => 'linkToDigitalProduct' in product;

export const getProductsWithAllDetails = (
  products: (Product | DigitalProduct)[],
  prices: Record<string, SkuPriceData>,
  locale: string,
  taxMessage = '',
): ProductWithPrices[] | DigitalProductWithoutPrices[] =>
  products
    .map((product) => {
      if (isDigitalProduct(product)) {
        return {
          ...product,
          amountFloat: 0,
          compareAtAmountFloat: 0,
          formattedAmount: '',
          formattedCompareAmount: '',
          discountRate: 0,
        } as DigitalProductWithoutPrices;
      }

      const price = prices[product.code];
      if (!price) {
        return null;
      }

      const { appliedCouponCode, purchaseLimit, stockQuantity } = price;

      return {
        ...product,
        formattedAmount: formatCurrencyWithLocale(
          price.totalAmountFloat,
          locale,
        ),
        formattedAmountWithTaxes: formatCurrencyWithLocale(
          price.totalAmountWithTaxesFloat,
          locale,
        ),
        formattedCompareAmount: formatCurrencyWithLocale(
          price.compareAtAmountFloat,
          locale,
        ),
        amountFloat: price.totalAmountFloat,
        compareAtAmountFloat: price.compareAtAmountFloat,
        discountRate: getProductDiscountRate(
          price.compareAtAmountFloat,
          price.totalAmountFloat,
        ),
        formattedInstallmentAmount: getFormattedInstallmentAmount(
          price.totalAmountFloat,
          product.numberOfInstallments,
          locale,
        ),
        formattedInstallmentOriginalAmount: getFormattedInstallmentAmount(
          price.compareAtAmountFloat,
          product.numberOfInstallments,
          locale,
        ),
        purchaseLimit,
        appliedCouponCode,
        stockQuantity,
        formattedTaxMessage: getTaxMessage(taxMessage, price, locale),
      } as ProductWithPrices;
    })
    .filter(Boolean);

export const getPurchaseLimitBySku = (
  sku: string,
  purchaseLimitsBySkuCodes: SkuCodePurchaseLimit[],
): number => {
  const purchaseLimit = purchaseLimitsBySkuCodes?.find(
    (price) => price.code === sku,
  )?.purchaseLimit;

  return purchaseLimit;
};

export const getDigitalProductCTALink = (
  url: string,
  query: NextApiRequest['query'],
): string => {
  const { products, ...queryWithoutProductsParam } = query;
  const hasQueryParams = Object.keys(queryWithoutProductsParam).length > 0;

  if (!hasQueryParams || !url) {
    return url;
  }

  const urlObj = new URL(url);
  const queryObj = new URLSearchParams(
    queryWithoutProductsParam as Record<string, string>,
  );

  queryObj.forEach((value, key) => {
    urlObj.searchParams.set(key, value);
  });

  return urlObj.toString();
};

// This is only a temporary solution and will be removed once the website pdps are live
const websitePosProductSelectionPathname: { [key: string]: string } = {
  'en-gb': 'shop-catalogue/point-of-sale/',
  'fr-fr': 'boutique-catalogue/point-of-sale/',
  'de-de': 'shop-katalog/point-of-sale/',
  'it-it': 'catalogo-negozio/point-of-sale/',
  'es-es': 'catalogo-tienda/point-of-sale/',
};

export const posProductTrackingIds: string[] = [
  'digital.pos_lite_add_ons',
  'digital.pos_lite_add_ons_italy',
];

export function getWebsitePosCtaLink({
  locale,
  query,
}: {
  locale: string;
  query: NextApiRequest['query'];
}): string {
  const websiteURL = process.env.NEXT_PUBLIC_WEBSITE_URL;

  const lowerCaseLocale = locale.toLowerCase();
  const productSelectionPath =
    websitePosProductSelectionPathname[lowerCaseLocale] || '';

  if (!productSelectionPath.length || !websiteURL.length) {
    return '';
  }

  const queryString = new URLSearchParams(
    query as Record<string, string>,
  ).toString();

  const pathname = productSelectionPath
    ? `${productSelectionPath}${queryString ? `?${queryString}` : ''}`
    : '';

  return `${websiteURL}/${locale}/${pathname}`;
}
