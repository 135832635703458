import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Body, Headline, Spinner } from '@sumup-oss/circuit-ui';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import {
  getFormattedInstallmentAmount,
  getPurchaseLimitBySku,
} from 'productSelection/services/product';
import type { Product } from 'productSelection/types/products';
import * as TestIds from 'shared/constants/TestIds';
import type { IUiCartFields } from 'shared/infra/contentful/contentful';
import type { SkuCodePurchaseLimit } from 'shared/infra/storefront/markets/prices';
import { useTypedSelector } from 'shared/store';
import type { OrderDetails as IOrderDetails } from 'shared/store/order/types';
import { SUMUP_ONE_SKU } from 'src/cart/services/CartOverviewService';
import { VAT_REVERSAL_PROMO_CODE } from 'src/experiments/vat-reversal';
import { formatCurrencyWithLocale } from 'utils/currency';
import { getOrderTotalAmountFormatted } from 'utils/tax';

import CouponForm from './components/CouponForm/CouponForm';
import { deliveryEstimateOptions } from './components/deliveryEstimateOptions';
import { Discount } from './components/Discount/Discount';
import { VatRateLine } from './components/VatRateLine/VatRateLine';
import {
  DescriptionContainer,
  FreeDeliveryText,
  InstallmentContainer,
  Installments,
  LoadingContainer,
  OverviewWrapper,
  StyledHr,
  StyledProductList,
  StyledProductListItem,
  TotalPrice,
} from './YourOrderCard.styles';

type OrderDetails = Pick<
  IOrderDetails,
  | 'id'
  | 'formattedSubtotalAmount'
  | 'formattedTotalTaxAmount'
  | 'taxRate'
  | 'formattedTotalAmountWithTaxes'
  | 'formattedDiscountAmount'
  | 'discountAmountCents'
  | 'couponCode'
>;

type OverviewContent = Pick<
  IUiCartFields,
  | 'quantityText'
  | 'subtotalText'
  | 'taxRateText'
  | 'deliveryEstimateText'
  | 'totalText'
  | 'overviewTitleText'
  | 'discountAppliedText'
  | 'freeText'
  | 'businessAccountDescription'
  | 'businessAccountCostsLabel'
  | 'couponCodeRemoveButtonLabel'
  | 'couponDiscountAppliedLabel'
>;

export interface Props extends OverviewContent, Partial<OrderDetails> {
  products?: Product[];
  usedInModal?: boolean;
  loading?: boolean;
  usedForCart?: boolean;
  isQuantitySelectionEnabled?: boolean;
  largestInstallmentInCart?: number;
  installmentsOnCheckout?: number;
  installments?: number;
  totalAmountFloat?: number;
  totalAmountWithTaxesFloat?: number;
  noMargin?: boolean;
  imgHydration?: 'lazy' | 'eager';
  onLineItemQuantityChange?: (product: Product) => (quantity: number) => void;
  hasBusinessAccount?: boolean;
  defaultTaxRate: number;
  purchaseLimitsBySkuCodes?: SkuCodePurchaseLimit[];
  hideProducts?: boolean;
  showCouponForm?: boolean;
  hasSumUpOne?: boolean;
}

/**
 * The YourOrder is the component responsible to present
 * an overview from user's order. It is mainly used inside
 * Cart component and Signup/Login Page.
 */
export const YourOrderCard: FC<Props> = ({
  id: orderId,
  quantityText,
  subtotalText,
  taxRateText,
  deliveryEstimateText,
  overviewTitleText,
  totalText,
  discountAppliedText,
  freeText,
  formattedSubtotalAmount = '',
  taxRate = 0,
  defaultTaxRate = 0,
  formattedTotalAmountWithTaxes,
  formattedDiscountAmount,
  products,
  usedInModal = false,
  usedForCart = false,
  isQuantitySelectionEnabled = true,
  largestInstallmentInCart = 1,
  installmentsOnCheckout,
  totalAmountFloat,
  noMargin = false,
  imgHydration = 'lazy',
  onLineItemQuantityChange = () => (): void => {},
  hasBusinessAccount = false,
  formattedTotalTaxAmount = '',
  businessAccountCostsLabel,
  businessAccountDescription,
  purchaseLimitsBySkuCodes,
  hideProducts = false,
  couponCode,
  showCouponForm: showCouponFormFromProps = false,
  couponDiscountAppliedLabel,
  couponCodeRemoveButtonLabel,
  hasSumUpOne = false,
  discountAmountCents,
}) => {
  const shippingAddress = useTypedSelector((s) => s.addresses.shippingAddress);
  const { locale, query } = useRouter();

  const installments = installmentsOnCheckout ?? largestInstallmentInCart;
  const hasInstallments = installments > 1;
  const formattedInstallmentAmount = getFormattedInstallmentAmount(
    totalAmountFloat,
    installments,
    locale,
  );

  const isVatReversalPromoCodeApplied = couponCode === VAT_REVERSAL_PROMO_CODE;

  const showCouponForm =
    showCouponFormFromProps && (!couponCode || isVatReversalPromoCodeApplied);
  const showSumUpOneDiscount = hasSumUpOne && discountAmountCents !== 0;

  const totalAmountFormatted = getOrderTotalAmountFormatted({
    shippingAddress,
    defaultTaxRate,
    totalAmountFloat,
    formattedTotalAmountWithTaxes,
    locale,
    doFallbackToDefaultTaxRate: true,
  });

  const formattedSubtotal = isVatReversalPromoCodeApplied
    ? formatCurrencyWithLocale(totalAmountFloat, locale)
    : formattedSubtotalAmount;

  return (
    <OverviewWrapper noMargin={noMargin} usedInModal={usedInModal}>
      {overviewTitleText && (
        <Headline
          size="s"
          as="h2"
          css={{ marginBottom: 'var(--cui-spacings-kilo)' }}
        >
          {overviewTitleText}
        </Headline>
      )}

      {formattedTotalAmountWithTaxes ? (
        <>
          {!hideProducts && !!products?.length && (
            <StyledProductList>
              {products.map((product) => (
                <StyledProductListItem
                  {...product}
                  key={product.id}
                  numberOfInstallments={installments}
                  formattedInstallmentAmount={getFormattedInstallmentAmount(
                    product.amountFloat,
                    installments,
                    locale,
                  )}
                  quantityText={quantityText}
                  usedForCart={usedForCart}
                  isQuantitySelectionEnabled={
                    isQuantitySelectionEnabled && product.code !== SUMUP_ONE_SKU
                  }
                  quantityLimit={getPurchaseLimitBySku(
                    product.code,
                    purchaseLimitsBySkuCodes,
                  )}
                  onChangeQuantity={onLineItemQuantityChange(product)}
                  imgHydration={imgHydration}
                  isVatReversalPromoCodeApplied={isVatReversalPromoCodeApplied}
                  locale={locale}
                />
              ))}
            </StyledProductList>
          )}

          {!usedForCart && <StyledHr />}

          {subtotalText && (
            <DescriptionContainer
              usedForCart={usedForCart}
              data-testid={TestIds.OrderOverview.Subtotal}
            >
              <Body>{subtotalText}</Body>
              <span>{formattedSubtotal}</span>
            </DescriptionContainer>
          )}

          <DescriptionContainer alignItems="start" usedForCart={usedForCart}>
            <Body>
              {deliveryEstimateText &&
                documentToReactComponents(
                  deliveryEstimateText,
                  deliveryEstimateOptions,
                )}
            </Body>
            <FreeDeliveryText>{freeText}</FreeDeliveryText>
          </DescriptionContainer>
          {hasBusinessAccount && (
            <DescriptionContainer alignItems="start" usedForCart={usedForCart}>
              <Body>{businessAccountDescription}</Body>
              <FreeDeliveryText>{businessAccountCostsLabel}</FreeDeliveryText>
            </DescriptionContainer>
          )}
          {((!!couponCode && !isVatReversalPromoCodeApplied) ||
            showSumUpOneDiscount) && (
            <DescriptionContainer
              data-testid={TestIds.OrderOverview.DiscountWrapper}
              usedForCart={usedForCart}
            >
              <Discount
                couponCode={couponCode}
                couponCodeRemoveButtonLabel={couponCodeRemoveButtonLabel}
                couponDiscountAppliedLabel={couponDiscountAppliedLabel}
                discountAppliedText={discountAppliedText}
                formattedDiscountAmount={formattedDiscountAmount}
                orderId={orderId}
                query={query}
                showRemoveButton={showCouponFormFromProps && !hasSumUpOne}
              />
            </DescriptionContainer>
          )}

          <VatRateLine
            shippingAddress={shippingAddress}
            taxRate={taxRate}
            defaultTaxRate={defaultTaxRate}
            usedForCart={usedForCart}
            taxRateText={taxRateText}
            formattedTotalTaxAmount={formattedTotalTaxAmount}
            totalAmountFloat={totalAmountFloat}
            locale={locale}
            doFallbackToDefaultTaxRate
          />

          <StyledHr />

          {showCouponForm && (
            <DescriptionContainer>
              <CouponForm products={products} disabled={hasSumUpOne} />
            </DescriptionContainer>
          )}

          <DescriptionContainer usedForCart={usedForCart}>
            <Headline size="s" as="h2" css={{ marginBottom: 0 }}>
              {totalText}
            </Headline>
            {hasInstallments ? (
              <InstallmentContainer>
                <Installments
                  size="m"
                  variant="subtle"
                  css={{ marginRight: 'var(--cui-spacings-bit)' }}
                  as="span"
                >
                  {installments}x
                </Installments>
                <Headline size="s" as="h2">
                  {formattedInstallmentAmount}
                </Headline>
              </InstallmentContainer>
            ) : (
              <TotalPrice data-testid={TestIds.OrderOverview.Total}>
                {totalAmountFormatted}
              </TotalPrice>
            )}
          </DescriptionContainer>
        </>
      ) : (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}
    </OverviewWrapper>
  );
};
