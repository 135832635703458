import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Hr } from '@sumup-oss/circuit-ui';

import { ProductListItem } from './components/ProductListItem';

export const DescriptionContainer = styled.div<{
  alignItems?: string;
  usedForCart?: boolean;
}>(
  ({ theme, usedForCart, alignItems = 'center' }) => css`
    display: flex;
    flex-direction: row;
    align-items: ${alignItems};
    justify-content: space-between;
    margin-bottom: var(--cui-spacings-mega);
    &:last-of-type {
      margin-bottom: 0;
    }

    ${usedForCart &&
    `
      margin-left: ${theme.spacings.mega};
      margin-right: ${theme.spacings.mega};
    `}
  `,
);

export const FreeDeliveryText = styled.span`
  color: var(--cui-border-success);
  font-weight: var(--cui-font-weight-bold);
`;

export const TotalPrice = styled.span`
  font-weight: var(--cui-font-weight-bold);
`;

export const StyledHr = styled(Hr)`
  border-top: 1px solid var(--cui-border-subtle);
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export const StyledProductListItem = styled(ProductListItem)<{
  usedForCart?: boolean;
  isQuantitySelectionEnabled?: boolean;
  isVatReversalPromoCodeApplied: boolean;
}>(
  ({ theme, usedForCart, isQuantitySelectionEnabled }) => `
    margin-bottom: ${theme.spacings.mega};

    :last-child {
      margin-bottom: ${
        isQuantitySelectionEnabled && usedForCart
          ? 'var(--cui-spacings-giga)'
          : '0'
      };
    }
  `,
);

export const OverviewWrapper = styled.div<{
  usedInModal: boolean;
  noMargin: boolean;
}>(
  ({ theme, usedInModal, noMargin = false }) => css`
    background-color: ${!usedInModal && 'var(--cui-bg-subtle)'};
    border-radius: var(--cui-border-radius-mega);
    padding: ${!usedInModal && 'var(--cui-spacings-giga)'};
    margin-top: var(--cui-spacings-giga);
    ${!usedInModal && `margin-bottom: ${theme.spacings.zetta};`}
    ${noMargin && 'margin: 0;'}
  `,
);

export const StyledProductList = styled.ul`
  max-height: 350px;
  overflow-y: auto;
`;

export const InstallmentContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Installments = styled(Body)`
  font-weight: var(--cui-font-weight-bold);
`;
